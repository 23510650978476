<!-- 确认订单组件 -->
<template>
  <div class="confirmOrder">
    <!-- 头部 -->
    <div class="confirmOrderHeader">
      <div class="headerContent">
        <p>
          <i class="el-icon-s-order"></i>
        </p>
        <p>确认订单</p>
      </div>
    </div>
    <!-- 头部END -->

    <!-- 主要内容容器 -->
    <div class="content">
      <!-- 选择地址 -->
      <el-card body-style="padding: 10px 30px 0 30px">
        <el-collapse v-model="activeCollapseName" accordion>
          <el-collapse-item name="address">
            <template slot="title">
              <div class="addressListTitle">
                <img :src="require('@/assets/imgs/addressLocation.png')" />
                <span>常用收货地址</span>
              </div>
            </template>
            <el-radio-group
              class="addressList"
              v-model="confirmAddress"
              @change="changeAddress"
            >
              <div
                v-for="(item, k) in addressListData"
                :key="k"
                class="addressListItem"
              >
                <el-radio v-model="confirmAddress" :label="item.id">{{
                  item.name +
                  "," +
                  item.mobile +
                  "," +
                  item.province_text +
                  item.city_text +
                  item.district_text +
                  item.address
                }}</el-radio>
              </div>
            </el-radio-group>
          </el-collapse-item>
        </el-collapse>
      </el-card>
      <!-- 添加地址 -->
      <el-card body-style="padding: 10px 30px 0 30px" style="margin-top: 10px">
        <div class="addressListTitle">
          <img :src="require('@/assets/imgs/newAdd.png')" />
          <span>新增收货地址</span>
        </div>
        <el-row :gutter="40" style="margin-bottom: 20px">
          <el-col :span="14" style="border-right: 1px solid #e7e7e7">
            <el-form
              ref="form"
              :model="form"
              :rules="rules"
              label-position="left"
              label-width="80px"
            >
              <el-row :gutter="15">
                <el-col>
                  <el-form-item label="省市区" prop="area">
                    <el-cascader
                      size="large"
                      :options="areaOptionData"
                      v-model="form.area"
                      @change="getExpressList"
                    >
                    </el-cascader>
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-form-item label="详细地址" prop="address">
                    <el-input
                      type="textarea"
                      :maxlength="80"
                      v-model="form.address"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="收货人" prop="name">
                    <el-input type="text" v-model="form.name"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="手机号" prop="mobile">
                    <el-input type="text" v-model="form.mobile"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="">
                    <el-checkbox v-model="form.default_flag"
                      >收藏该地址</el-checkbox
                    >
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-col>
          <el-col :span="10">
            <div class="AIAddress">
              <div class="AILabel">地址解析:</div>
              <div>
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 6, maxRows: 6 }"
                  placeholder="将其他平台的订单详情收货信息粘贴到此处"
                  v-model="aiAddressString"
                ></el-input>
              </div>
              <div class="AIButton">
                <el-button type="primary" size="small" @click="AIAddress"
                  >开始解析</el-button
                >
              </div>
            </div>
          </el-col>
        </el-row>
      </el-card>
      <el-card body-style="padding: 10px 30px 0 30px" style="margin-top: 10px">
        <div class="senderBox">
          <div class="boxTitle">选择发件信息</div>
          <div class="senderInfo">
            <span style="margin-right: 10px">发件信息:</span>
            <span style="margin-right: 10px"
              >{{ sender.name }}&nbsp;{{ sender.mobile }}</span
            >
            <el-button
              plain
              type="danger"
              size="mini"
              style="font-size: 14px"
              @click="senderFormVisible = true"
              >修改</el-button
            >
          </div>
        </div>
      </el-card>
      <el-card body-style="padding: 10px 30px 0 30px" style="margin-top: 10px">
        <div class="remarkBox">
          <div class="remarkTitle">
            <i class="el-icon-edit-outline"></i>订单附言
          </div>
          <div class="remarkInput">
            <el-input
              type="text"
              v-model="remark"
              placeholder="如有特殊需求，请在此处留言"
              size="large"
              style="width: 1000px"
            ></el-input>
          </div>
        </div>
      </el-card>
      <el-card body-style="padding: 20px 30px 0 30px" style="margin-top: 10px">
        <div class="orderGoods">
          <div class="boxTitle" style="padding-bottom: 5px">
            <i class="el-icon-shopping-bag-2"></i>确认订单信息
            <div>
              提示：本次采购属于商业贸易行为，不适于7天无理由退换政策。充值(支付)金额仅用于产品零差价代拿货(发货)。产品知识产权纠纷自行负责，与毛绒之家平台无关。
              <span>
                <el-popover placement="top" width="500" trigger="click">
                  <div style="padding: 10px 10px">
                    <p style="font-weight: bold">
                      1.充值(支付)金额仅用于产品零差价代拿货(发货)，产品知识产权纠纷自行负责，或联系产品供货商家辨别，与毛绒之家平台无关。
                    </p>
                    <p>
                      2.正常工作日，16点之前订单尽量当天发货，16点之后的订单第二天发货。
                    </p>
                    <p>
                      3.除劳动节，国庆节，春节等重大节日放假外，其他一律无休。
                    </p>
                    <p>
                      4.由于各电商平台快递单号类型不同，请选择对应的平台快递下单，避免物流单号发不了货。
                    </p>
                    <p>
                      5.如规格，价格有误，以商家实际价格为准，请联系客服修改。
                    </p>
                    <p>6.平台商品不支持7天无理由退货。</p>
                    <p>
                      7.为防止恶意下单，已充值或下单产生的退货退款将以同等值金币返还到用户账户，金币在本平台可用于支付产品、代发、快递费用。如需退现金应支付1%的手续费（此手续费在支付宝支付成功后已形成）
                    </p>
                  </div>
                  <a slot="reference">查看详情</a>
                </el-popover>
              </span>
            </div>
          </div>
          <div class="goodsList">
            <el-table :data="goods" style="width: 100%">
              <el-table-column label="商品" width="300">
                <template slot-scope="scope">
                  <div class="goodsName">
                    <el-image
                      :src="scope.row.thumbnail"
                      style="width: 70px; height: 70px"
                    ></el-image>
                    <div class="goodsNameText">
                      <div>
                        <span style="color: #c0c4cc">{{ scope.row.sname }}</span
                        >&nbsp;{{ scope.row.goodsName }}
                      </div>
                      <div>货号：{{ scope.row.goods_sn }}</div>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="sname"
                label="商家"
                width="200"
              ></el-table-column>
              <el-table-column label="规格" width="200">
                <template slot-scope="scope">
                  <el-tag size="medium"
                    >颜色：{{ scope.row.color }}，尺寸：{{
                      scope.row.size
                    }}</el-tag
                  >
                </template>
              </el-table-column>
              <el-table-column label="单价" width="100">
                <template slot-scope="scope">
                  <div>￥{{ scope.row.price }}</div>
                </template>
              </el-table-column>
              <el-table-column
                prop="weight"
                label="商品毛重（克）"
              ></el-table-column>
              <el-table-column
                prop="num"
                label="数量"
                width="60"
              ></el-table-column>
              <el-table-column label="小计" align="center">
                <template slot-scope="scope">
                  <div>
                    ￥{{
                      parseFloat(scope.row.price * scope.row.num).toFixed(2)
                    }}
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <el-row>
              <el-col :span="24" class="orderSum">
                共&nbsp;<span style="color: #f95057">{{ GoodsTotalNum }}</span>
                件商品，<span style="margin-right: 20px">&nbsp;</span
                >合计：<span class="orderMoney"
                  >￥{{ parseFloat(GoodsTotalPrice).toFixed(2) }}</span
                >
              </el-col>
            </el-row>
          </div>
          <div class="express">
            <el-tooltip
              content="包邮商品和不包邮商品混合采购时，不包邮商品需要补续重费！"
              placement="bottom"
            >
              <i class="el-icon-question"></i>
            </el-tooltip>
            快递选择:
            <el-select
              v-model="express_company"
              size="mini"
              :disabled="expressDisabled"
              placeholder="地址未确定或当地不能送达"
              @change="changeExpressCompany"
              style="min-width: 280px"
            >
              <el-option
                v-for="(item, key) in expressOptions"
                :label="item.company + ':￥' + item.fee + '元'"
                :value="item.company"
                :key="key"
              >
                <span style="float: left">{{
                  item.company + ":￥" + item.fee + "元"
                }}</span>
              </el-option>
            </el-select>
            <span class="expressWeight"
              >订单重量：<span style="font-weight: bold"
                >{{ GoodsTotalWeight + 50 * package_num }}克</span
              ></span
            >
            <div class="expressFee">
              运费: <span>￥{{ express_fee }}</span>
            </div>
          </div>
          <div style="clear: both"></div>
          <el-row class="userService">
            <el-col :span="16" class="serviceText">
              <i class="el-icon-question"></i>
              增值服务:
              <span class="textSpan">
                <el-tooltip
                  content="商品从档口采购并为您打包发货，所以平台将按商品重量收取代发费。"
                  placement="bottom"
                >
                  <i class="el-icon-question"></i>
                </el-tooltip>
                代发服务费{{ daifa_fee }}元
              </span>
              <span class="textSpan">{{
                check_fee == 0 ? "普通质检" : "普通质检" + check_fee + "元"
              }}</span>
              <span class="textSpan">{{
                package_fee == 0 ? "普通快递袋" : "编织袋2.00元"
              }}</span>
              <span class="textSpan">标准内包装0.0</span>
              <span class="textSpan" v-if="brand_fee > 0">{{
                "自定义吊牌" + brand_fee.toFixed(2)
              }}</span>
              <span class="textSpan" v-if="wishcard_fee > 0">{{
                "贺卡" + wishcard_fee
              }}</span>
              <span
                style="color: #3e6bc4; padding-left: 20px; cursor: pointer"
                @click="toggleChange"
                >修改服务<i
                  :class="toggle_up ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
                  style="padding-left: 5px"
                ></i
              ></span>
            </el-col>
            <el-col :span="8" style="text-align: right; padding-right: 25px">
              <span>服务费合计:</span>
              <span style="color: #f95057; padding-left: 30px; font-size: 14px"
                >￥{{
                  parseFloat(
                    parseFloat(daifa_fee) +
                      parseFloat(check_fee) +
                      parseFloat(package_fee) +
                      parseFloat(brand_fee) +
                      parseFloat(wishcard_fee)
                  ).toFixed(2)
                }}</span
              >
            </el-col>
          </el-row>
          <el-row class="serviceDetail" v-show="!toggle_up">
            <el-col
              :span="24"
              style="background: #fff; height: 500px; padding: 5px 20px"
            >
              <el-row class="tableCol" style="font-weight: bold">
                <el-col :span="2" style="text-align: left">增值服务</el-col>
                <el-col :span="13" style="text-align: left"
                  >设置当前为默认</el-col
                >
                <el-col :span="3">单价</el-col>
                <el-col :span="3">数量</el-col>
                <el-col :span="3">小计</el-col>
              </el-row>
              <el-row class="tableCol">
                <el-col :span="2" style="text-align: left"
                  >代发服务<i class="el-icon-question questionIcon"></i
                ></el-col>
                <el-col :span="13" style="text-align: left"
                  ><div class="serviceTagActive">代发服务费</div></el-col
                >
                <el-col :span="3">￥{{ daifa_fee }}</el-col>
                <el-col :span="3">1</el-col>
                <el-col :span="3" style="color: #f95057"
                  >￥{{ daifa_fee }}</el-col
                >
              </el-row>
              <el-row class="tableCol">
                <el-col :span="2" style="text-align: left"
                  >质检选择<i class="el-icon-question questionIcon"></i
                ></el-col>
                <el-col :span="13" style="text-align: left">
                  <div
                    :class="check_fee == 0 ? 'serviceTagActive' : 'serviceTag'"
                    @click="changeCheckFee(0)"
                  >
                    普通质检
                  </div>
                  <div
                    :class="check_fee > 0 ? 'serviceTagActive' : 'serviceTag'"
                    @click="changeCheckFee(3)"
                  >
                    高级质检
                  </div>
                  <span>质检服务说明>></span>
                </el-col>
                <el-col :span="3">{{
                  check_fee == 0 ? "&nbsp;" : "￥3.00"
                }}</el-col>
                <el-col :span="3">{{
                  check_fee == 0 ? "&nbsp;" : GoodsTotalNum
                }}</el-col>
                <el-col :span="3" style="color: #f95057">{{
                  check_fee == 0 ? "免费" : "￥" + check_fee
                }}</el-col>
              </el-row>
              <el-row class="tableCol">
                <el-col :span="2" style="text-align: left"
                  >外包装<i class="el-icon-question questionIcon"></i
                ></el-col>
                <el-col :span="13" style="text-align: left">
                  <div
                    :class="
                      package_fee == 0 ? 'serviceTagActive' : 'serviceTag'
                    "
                    v-if="GoodsTotalWeight < 3000"
                    @click="changePackage(0)"
                  >
                    普通快递袋
                  </div>
                  <div
                    :class="package_fee > 0 ? 'serviceTagActive' : 'serviceTag'"
                    @click="changePackage(2)"
                  >
                    编织袋
                    <el-popover placement="right" trigger="hover" width="400">
                      <el-image
                        style="width: 100%; height: 100%"
                        :src="require('@/assets/imgs/bzd.png')"
                      />
                      <i
                        class="el-icon-zoom-in"
                        slot="reference"
                        style="padding: 5px 5px"
                      ></i>
                    </el-popover>
                  </div>
                </el-col>
                <el-col :span="3">{{
                  package_fee == 0 ? "&nbsp;" : "￥2.00"
                }}</el-col>
                <el-col :span="3">
                  <span v-if="package_fee == 0">&nbsp;</span>
                  <el-input-number
                    v-else
                    v-model.trim="package_num"
                    :min="1"
                    size="mini"
                    @change="changePackageNum"
                  ></el-input-number>
                </el-col>
                <el-col :span="3" style="color: #f95057">{{
                  package_fee == 0 ? "免费" : "￥" + package_fee
                }}</el-col>
              </el-row>
              <el-row class="tableCol">
                <el-col :span="2" style="text-align: left"
                  >内包装<i class="el-icon-question questionIcon"></i
                ></el-col>
                <el-col :span="13" style="text-align: left">
                  <div class="serviceTagActive">标准内包装</div>
                </el-col>
                <el-col :span="3">&nbsp;</el-col>
                <el-col :span="3">&nbsp;</el-col>
                <el-col :span="3" style="color: #f95057">免费</el-col>
              </el-row>
              <el-row class="tableCol">
                <el-col :span="2" style="text-align: left"
                  >吊牌<i class="el-icon-question questionIcon"></i
                ></el-col>
                <el-col :span="13" style="text-align: left">
                  <div
                    :class="brand_fee > 0 ? 'serviceTag' : 'serviceTagActive'"
                    @click="setBrand(0)"
                  >
                    无吊牌
                  </div>
                  <div
                    :class="brand_fee > 0 ? 'serviceTagActive' : 'serviceTag'"
                    @click="setBrand(1.0)"
                  >
                    <i
                      class="el-icon-circle-plus-outline"
                      style="padding: 5px 5px"
                    ></i
                    >自定义吊牌
                  </div>
                  <el-tooltip
                    content="若您需要其他定制服务，请联系我们， 联系电话：17130041850。"
                    placement="top"
                    style="font-size: 16px; color: #f95057; padding-left: 5px"
                    ><i class="el-icon-warning-outline"></i
                  ></el-tooltip>
                  <el-input
                    type="text"
                    placeholder="自定义吊牌"
                    v-model.trim="brand_word"
                    v-if="brand_fee > 0"
                    clearable
                    style="margin: 5px 0"
                  ></el-input>
                </el-col>
                <el-col :span="3">{{
                  brand_fee > 0 ? "￥" + brand_fee : "&nbsp;"
                }}</el-col>
                <el-col :span="3">{{ brand_fee > 0 ? 1 : "&nbsp;" }}</el-col>
                <el-col :span="3" style="color: #f95057">{{
                  brand_fee > 0 ? "￥" + brand_fee : "免费"
                }}</el-col>
              </el-row>
              <el-row class="tableCol">
                <el-col :span="2" style="text-align: left"
                  >其他服务<i class="el-icon-question questionIcon"></i
                ></el-col>
                <el-col :span="13" style="text-align: left">
                  <p>精美贺卡</p>
                  <div style="display: flex; flex-direction: row">
                    <div
                      :class="
                        wishcard_type == 5
                          ? 'brandTitleTagActive'
                          : 'brandTitleTag'
                      "
                      @click="setWishCard(5, 0.2, '精美贺卡（空）')"
                    >
                      精美贺卡（空）
                    </div>
                  </div>
                  <div
                    :class="
                      wishcard_type == 6
                        ? 'brandTitleTagActive'
                        : 'brandTitleTag'
                    "
                    style="width: 150px; margin: 10px 0"
                    @click="setWishCard(6, 1.0, '')"
                  >
                    高档精美贺卡（可代写）
                  </div>
                  <el-input
                    type="textarea"
                    :rows="3"
                    placeholder="请输入祝福语！若不输入则空贺卡寄出！"
                    v-model.trim="wishcard_word"
                    v-if="wishcard_fee == 1"
                    style="margin-bottom: 10px"
                  >
                  </el-input>
                </el-col>
                <el-col :span="3">{{
                  wishcard_fee > 0 ? "￥" + wishcard_fee : "&nbsp;"
                }}</el-col>
                <el-col :span="3">{{ wishcard_fee > 0 ? 1 : "&nbsp;" }}</el-col>
                <el-col :span="3" style="color: #f95057">{{
                  wishcard_fee > 0 ? "￥" + wishcard_fee : "&nbsp;"
                }}</el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-row class="userGold">
            <el-col :span="16" class="goldText">
              <i class="el-icon-coin" style=""></i>
              抵扣金币：<span v-if="userGold <= 0">无抵扣金币</span>
            </el-col>
            <el-col :span="8" class="goldNum"
              >金币：<span
                >-￥{{ Math.min(userGold, countAllPrice()) }}</span
              ></el-col
            >
          </el-row>
          <el-row style="height: 15px">&nbsp;</el-row>
        </div>
      </el-card>
      <el-card body-style="padding: 0" style="margin-top: 10px">
        <el-row>
          <el-col :span="16" class="showAddressAndUser">
            <div>
              配送至：{{ shipAddress }}
              <span>收货人：{{ form.name }}</span>
            </div>
          </el-col>
          <el-col :span="8" class="showOrderPrice">
            <div>
              合计（含运费）：<span
                >￥{{
                  Math.max(parseFloat(countAllPrice() - userGold).toFixed(2), 0)
                }}</span
              >
              <el-button
                type="danger"
                size="large"
                @click="addOrder"
                class="addOrderButton"
                >提交订单</el-button
              >
            </div>
          </el-col>
        </el-row>
      </el-card>
    </div>
    <!-- 主要内容容器END -->

    <!-- 发件人Dialog  -->
    <el-dialog
      title="发件信息"
      :visible.sync="senderFormVisible"
      :close-on-click-modal="false"
    >
      <el-form :model="sender" label-width="100">
        <el-form-item label="发件人">
          <el-input v-model="sender.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input v-model="sender.mobile" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="senderFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveSendInfo">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { regionData, CodeToText } from "element-china-area-data";
import { getTotalPrice } from "@/util/common";

export default {
  name: "ConfirmOrder",
  data() {
    return {
      order_visual_no: "",
      senderFormVisible: false,
      userGold: 0.0,
      goods: [], // 订单商品列表
      areaOptionData: regionData,
      activeCollapseName: "", // 下拉
      confirmAddress: 0, // 选择的地址id
      addressListData: [],
      form: {
        area: [],
        address: "",
        name: "",
        mobile: "",
        default_flag: false,
      },
      aiAddressString: "",
      sender: {
        name: "",
        mobile: "",
      },
      remark: "",
      expressDisabled: true,
      expressOptions: [],
      express_company: "",
      express_fee: 0,
      gold: 0,
      toggle_up: true,
      daifa_fee: 1,
      check_fee: 0,
      package_num: 0,
      package_fee: 0,
      brand_fee: 0,
      brand_word: "",
      wishcard_type: 0,
      wishcard_fee: 0,
      wishcard_word: "",
      rules: {
        area: [
          {
            required: true,
            message: "请选择省市区",
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            message: "请填写地址",
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "请填写姓名",
            trigger: "blur",
          },
        ],
        mobile: [
          {
            required: true,
            message: "请填写手机号",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.order_visual_no = this.$route.query.order_no;
    // 判断商品
    if (!localStorage.getItem("orderGoods" + this.order_visual_no)) {
      this.notifyError("请勾选商品后再下单");
      this.$router.go(-1);
      return false;
    }
    // 判断用户
    if (!this.$store.getters.getUser) {
      this.$store.dispatch("setShowLogin", true);
      return false;
    }
    // 判断收件人
    if (localStorage.getItem("sender")) {
      this.sender = JSON.parse(localStorage.getItem("sender"));
    }
    this.goods = JSON.parse(
      localStorage.getItem("orderGoods" + this.order_visual_no)
    );
    if (this.GoodsTotalWeight >= 3000) {
      this.package_num = 1;
      this.package_fee = 2.0;
    }
    this.daifa_fee = (
      ((this.GoodsTotalWeight + this.package_num * 50) * 1.5) /
      1000
    ).toFixed(2);
    this.getAddressList();
    this.getUserGold();
  },
  computed: {
    /**
     * 统计商品总数
     */
    GoodsTotalNum: function () {
      let count = 0;
      if (this.goods.length) {
        this.goods.map((item) => {
          count += item.num;
          return false;
        });
      }
      return count;
    },
    /**
     * 统计商品总重
     */
    GoodsTotalWeight: function () {
      let weight = 0;
      if (this.goods.length) {
        this.goods.map((item) => {
          weight += item.weight * item.num;
          return false;
        });
      }
      return weight;
    },
    /**
     * 统计商品总金额
     */
    GoodsTotalPrice: function () {
      let total = 0;
      if (this.goods.length) {
        this.goods.map((item) => {
          total += parseFloat(item.price) * item.num;
          return false;
        });
      }
      return parseFloat(Math.max(total, 0)).toFixed(2);
    },
    /**
     * 显示收件地址
     */
    shipAddress: function () {
      let addr = "";
      if (this.form.area[0]) {
        this.form.area.map((code) => {
          addr = addr + CodeToText[code] + " ";
        });
      }
      addr += this.form.address;
      return addr;
    },
  },
  methods: {
    ...mapActions(["deleteShoppingCart"]),
    /**
     * 获取用户金币
     */
    async getUserGold() {
      const { data } = await this.$axios.get(
        this.$apiBaseUrl +
          "/web/gold/query?uid=" +
          this.$store.getters.getUser.id
      );
      const gold = parseFloat(data.data.gold);
      this.userGold = gold >= 0 ? gold.toFixed(2) : 0;
    },
    /**
     * 异步获取历史地址
     */
    async getAddressList() {
      const { data } = await this.$axios.get(
        this.$apiBaseUrl +
          "/web/address/list?uid=" +
          this.$store.getters.getUser.id
      );
      const list = data.data.list.map((v) => {
        v.province_text = CodeToText[v.province];
        v.city_text = CodeToText[v.city];
        v.district_text = CodeToText[v.district];
        return v;
      });
      this.addressListData = list;
    },
    /**
     * 计算总价
     */
    countAllPrice() {
      return getTotalPrice(
        this.GoodsTotalPrice,
        this.express_fee,
        this.daifa_fee,
        this.check_fee,
        this.package_fee,
        this.brand_fee,
        this.wishcard_fee
      );
    },
    /**
     * 保存地址切换
     */
    changeAddress() {
      const chooseAddress = this.addressListData.filter((item) => {
        return item.id == this.confirmAddress;
      });
      if (chooseAddress[0]) {
        this.form = {
          area: [
            chooseAddress[0].province,
            chooseAddress[0].city,
            chooseAddress[0].district,
          ],
          address: chooseAddress[0].address,
          name: chooseAddress[0].name,
          mobile: chooseAddress[0].mobile.replace(/[^0-9]/gi, ""),
        };
        this.getExpressList(this.form.area);
      }
    },

    /**
     * AI地址解析
     */
    async AIAddress() {
      if (!this.aiAddressString) {
        this.notifyError("请输入收货地址!");
        return false;
      }
      const { data } = await this.$axios.get(
        this.$apiBaseUrl + "/web/address/ai?text=" + this.aiAddressString
      );

      if (data.code === 1) {
        this.form = {
          area: [
            data.data.province_code,
            data.data.city_code,
            data.data.county_code,
          ],
          address: data.data.town + data.data.detail,
          name: data.data.person,
          mobile: data.data.phonenum,
        };
        this.form.mobile = this.form.mobile.replace(/[^0-9]/gi, "");
        this.getExpressList(this.form.area);
      } else {
        this.notifyError("解析地址失败，请稍后再试!");
      }
    },

    /**
     * 省市区切换获取快递价目
     * @param {*} value
     */
    async getExpressList(value) {
      if (value[0]) {
        const weight = this.GoodsTotalWeight + this.package_num * 50;
        const { data } = await this.$axios.post(
          this.$apiBaseUrl + "/web/express/fee",
          {
            weight: weight,
            code: value[0],
          }
        );
        if (data.code === 1) {
          this.expressOptions = data.data;
          this.expressDisabled = false;
          const expressListData = data.data;
          this.express_company = expressListData[0]
            ? expressListData[0].company
            : "";
          this.express_fee = expressListData[0] ? expressListData[0].fee : 8888;
        }
      }
    },

    /**
     * 切换快递公司
     */
    changeExpressCompany(value) {
      const selectOptions = this.expressOptions.filter((item) => {
        return item.company === value;
      });
      this.express_company = selectOptions[0] ? selectOptions[0].company : 0;
      this.express_fee = selectOptions[0] ? selectOptions[0].fee : 0;
    },

    /**
     * 下拉服务修改
     */
    toggleChange() {
      this.toggle_up = !this.toggle_up;
    },

    /**
     * 质检切换
     * @param {*} value
     */
    changeCheckFee(value) {
      this.check_fee = Math.ceil(this.GoodsTotalNum * value).toFixed(2);
    },

    /**
     * 外包装切换
     * @param {*} value
     */
    changePackage(value) {
      this.package_num = 1;
      this.package_fee = (this.package_num * parseInt(value)).toFixed(2);
    },

    /**
     * 编织袋数量变更
     * @param {*} num
     */
    changePackageNum(num) {
      this.package_num = num;
      this.package_fee = (this.package_num * 2).toFixed(2);
      const weight = this.GoodsTotalWeight + this.package_num * 50;
      if (
        parseInt(weight / 1000) !==
        parseInt((this.GoodsTotalWeight + 50) / 1000)
      ) {
        this.getExpressList(this.form.area);
      }
    },

    /**
     * 自定义吊牌
     */
    setBrand(value) {
      this.brand_fee = value * this.GoodsTotalNum;
    },

    /**
     * 变更卡片
     * @param {*} type
     * @param {*} value
     * @param {*} word
     */
    setWishCard(type, value, word) {
      this.wishcard_type = type;
      this.wishcard_fee = value;
      this.wishcard_word = word;
    },

    /**
     * 保存发件人信息
     */
    saveSendInfo() {
      localStorage.setItem("sender", JSON.stringify(this.sender));
      this.senderFormVisible = false;
    },

    /**
     * 提交订单
     */
    async addOrder() {
      if (
        this.$refs["form"].validate((valid) => {
          if (!valid) {
            this.notifyError("请确认收货信息！");
            return false;
          }
        })
      )
        if (!this.$store.getters.getUser.id) {
          this.notifyError("登录异常，请重新登录！");
          return false;
        }
      const removeGoodsSns = [];
      const postGoodsList = this.goods.map((item) => {
        if (item.check) {
          removeGoodsSns.push(item.goods_sn);
        }
        item.goods_num = item.num;
        return item;
      });
      const payTotal = this.countAllPrice();
      this.gold = this.getUserCostGold(payTotal);
      const { data } = await this.$axios.post(
        this.$apiBaseUrl + "/web/order/save",
        this.getPostOrderData(postGoodsList)
      );
      // 清除缓存
      localStorage.removeItem("orderGoods" + this.order_visual_no);
      this.clearCart(removeGoodsSns);
      this.notifySucceed("下单成功，即将跳转支付页面!");
      if (data.code === 1) {
        // 全金币支付
        if (parseFloat(this.userGold) >= parseFloat(payTotal)) {
          this.doGoldPay(data.data.order_no);
        } else {
          // 支付宝支付
          window.location.href =
            this.$apiBaseUrl +
            "/web/pay/app?web=1&order_no=" +
            data.data.order_no;
        }
      } else {
        this.notifyError("下单失败，请确认订单信息!");
      }
    },

    /**
     * 获取用户订单可用金币
     * @param {*} total
     */
    getUserCostGold(total) {
      if (parseFloat(this.userGold) <= 0) return 0;
      if (parseFloat(this.userGold) <= parseFloat(total)) {
        return parseFloat(this.userGold);
      } else {
        return parseFloat(total);
      }
    },

    /**
     * 获取订单参数
     */
    getPostOrderData(goodsList) {
      return {
        uid: this.$store.getters.getUser.id,
        username: this.$store.getters.getUser.username,
        goods_total: parseFloat(this.GoodsTotalPrice),
        express_company: this.express_company,
        express_fee: this.express_fee,
        address: this.confirmAddress,
        gold: this.gold,
        remark: this.remark,
        goods_list: JSON.stringify(goodsList),
        daifa_fee: this.daifa_fee,
        check_fee: this.check_fee,
        package_fee: this.package_fee,
        brand_fee: this.brand_fee,
        wishcard_fee: this.wishcard_fee,
        brand_word: this.brand_word,
        wishcard_word: this.wishcard_word,
        province: this.form.area[0],
        province_text: CodeToText[this.form.area[0]],
        city: this.form.area[1],
        city_text: CodeToText[this.form.area[1]],
        district: this.form.area[2],
        district_text: CodeToText[this.form.area[2]],
        addr: this.form.address,
        name: this.form.name,
        mobile: this.form.mobile,
        flag: this.form.default_flag,
      };
    },

    /**
     * 清理购物车
     * @param {*} goodsSns
     */
    async clearCart(goodsSns) {
      await this.$axios.post(this.$apiBaseUrl + "/web/cart/delete", {
        uid: this.$store.getters.getUser.id,
        goods_sn: goodsSns.join(","),
      });
    },

    /**
     * 全金币付款
     * @param {*} order_no
     */
    async doGoldPay(order_no) {
      const { data, msg } = await this.$axios.get(
        this.$apiBaseUrl + "/web/gold/pay?order_no=" + order_no
      );
      if (data.code === 1) {
        this.notifySucceed("支付成功，即将跳转我的订单页面!");
        this.$router.push("order");
      } else {
        this.notifyError(msg);
      }
    },
  },
};
</script>
<style scoped>
.confirmOrder {
  background-color: #f4f4f4;
  padding-bottom: 20px;
}
.el-cascader {
  width: 400px;
}
.el-collapse {
  border: 0;
}
/* 头部CSS */
.confirmOrder .confirmOrderHeader {
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 20px;
}
.confirmOrderHeader .headerContent {
  width: 1225px;
  margin: 0 auto;
  height: 80px;
}
.confirmOrderHeader .headerContent p {
  float: left;
  font-size: 28px;
  line-height: 80px;
  color: #424242;
  margin-right: 20px;
}
.confirmOrderHeader .headerContent p i {
  font-size: 45px;
  color: #fa3739;
  line-height: 80px;
}
/* 头部CSS END */

/* 主要内容容器CSS */
.confirmOrder .content {
  width: 1225px;
  margin: 0 auto;
  padding: 0 0;
}
/* 选择地址CSS */
.addressListTitle {
  line-height: 50px;
  height: 50px;
  font-size: 16px;
  color: #181619;
}
.addressListTitle span {
  margin-left: 5px;
  position: relative;
  top: -3px;
}
.addressList {
  margin: 10px 0;
  width: 100%;
}
.addressListItem {
  height: 25px;
  line-height: 25px;
  padding: 12px 10px 0 10px;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  margin-bottom: 5px;
}
/* 选择地址CSS END */
/*  */
.AIAddress .AILabel {
  font-size: 14px;
  line-height: 30px;
  height: 30px;
}
.AIAddress .AIButton {
  margin: 10px 0;
}
.AIAddress .AIButton .el-button {
  border-radius: 8px;
  width: 92px;
  height: 36px;
  background: #357aff;
}
/* 发件人CSS */
.senderBox {
  padding-bottom: 15px;
}
.boxTitle {
  font-weight: bold;
  font-size: 16px;
  height: 30px;
  line-height: 30px;
  padding-bottom: 15px;
}
.boxTitle i {
  margin-right: 10px;
  color: #ff414d;
  font-size: 20px;
}
.boxTitle div {
  font-size: 12px;
  color: #f56c6c;
  display: inline-block;
}
.boxTitle div span {
  color: blue;
  cursor: pointer;
}
.senderInfo {
  font-size: 14px;
}
/* 发件人CSS END */
/* 附言CSS */
.remarkBox {
  display: flex;
  flex-direction: row;
  padding: 0 0 5px 0;
}
.remarkTitle {
  height: 50px;
  font-size: 16px;
  line-height: 50px;
}
.remarkTitle i {
  margin-right: 10px;
  color: #ff414d;
  font-size: 20px;
}
.remarkInput {
  position: relative;
  top: 5px;
  left: 10px;
}
/* 附言CSS END */
/* 订单商品CSS */
.boxTitle i {
  margin-right: 10px;
  color: #ff414d;
  font-size: 20px;
}
.goodsList .goodsName {
  display: flex;
  flex-direction: row;
}
.goodsName .goodsNameText {
  padding: 0 10px;
  color: #3d3d40;
  font-size: 14px;
}
.goodsName .goodsNameText div {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 180px;
  overflow: hidden;
}
/* 订单商品CSS END */
/* 快递CSS */
.express,
.userGold,
.userService,
.serviceDetail,
.orderSum {
  padding: 15px 15px;
  font-size: 12px;
  color: #757578;
  background: #f8f8f8;
  border-bottom: 1px solid #e7e7e7;
}
.express .el-icon-question,
.userGold .el-icon-coin,
.userService .el-icon-question {
  font-size: 15px;
  color: #f95057;
}
.express .el-select {
  margin-left: 15px;
  color: rgb(117, 117, 120);
}
.express .expressWeight {
  color: #000;
  margin-left: 15px;
}
.express .expressFee {
  float: right;
  position: relative;
  width: 115px;
  padding-right: 15px;
}
.express .expressFee span {
  color: #f95057;
  font-size: 14px;
  margin-left: 30px;
}
/* 快递CSS END */
/* 抵扣金币CSS */
.userGold .goldText span {
  margin-left: 10px;
}
.userGold .goldNum {
  text-align: right;
  padding-right: 25px;
}
.userGold .goldNum span {
  margin-left: 20px;
  color: #f95057;
  font-size: 14px;
}
/* 抵扣金币CSS END */
/* 增值服务CSS */
.userService .textSpan {
  padding: 6px 12px;
  border-right: 1px solid #e7e7e7;
}
.serviceDetail .tableCol {
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #e7e7e7;
}
.serviceDetail .questionIcon {
  padding-left: 8px;
  color: #f95057;
}
/* 包装、服务费、质检 */
.serviceDetail .serviceTag {
  float: left;
  width: 104px;
  height: 33px;
  line-height: 33px;
  text-align: center;
  border-radius: 5px;
  font-size: 12px;
  margin-right: 10px;
  cursor: pointer;
  border: 1px solid #bbbbbb;
  margin-top: 2px;
}
.serviceDetail .serviceTagActive {
  float: left;
  width: 104px;
  height: 33px;
  line-height: 33px;
  text-align: center;
  border-radius: 5px;
  font-size: 12px;
  margin-right: 10px;
  cursor: pointer;
  color: #ff0101;
  border: 1px solid #f95057 !important;
  margin-top: 2px;
}
.brandTitleTag {
  width: 96px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  border-radius: 5px;
  font-size: 12px;
  margin-right: 10px;
  cursor: pointer;
  border: 1px solid #bbbbbb;
}
.brandTitleTagActive {
  width: 96px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  border-radius: 5px;
  font-size: 12px;
  margin-right: 10px;
  cursor: pointer;
  color: #ff0101;
  border: 1px solid #f95057 !important;
}
/* 增值服务CSS END */
/* 订单合计CSS */
.orderSum {
  font-size: 14px;
  color: #000;
  text-align: right;
  padding-right: 50px;
}
.orderSum .orderMoney {
  margin-left: 20px;
  font-weight: bold;
  color: #f95057;
  font-size: 18px;
  letter-spacing: 0.1em;
}
/* 订单合计CSS END */
.showAddressAndUser {
  font-size: 15px;
  font-weight: bold;
  height: 50px;
  line-height: 50px;
  padding: 0;
  padding-left: 15px;
}
.showAddressAndUser span {
  margin-left: 20px;
}
.showOrderPrice {
  text-align: right;
  height: 50px;
  line-height: 50px;
  font-size: 15px;
}
.showOrderPrice span {
  font-weight: bold;
  color: #f95057;
  font-size: 18px;
  letter-spacing: 0.1em;
  margin-right: 10px;
}
.addOrderButton {
  height: 52px;
  position: relative;
  right: 0;
  top: 0;
  border-radius: 0;
}
/* 主要内容容器CSS END */
</style>
